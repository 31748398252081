import React, { useState, useEffect } from 'react';
import { Button, Row, Col, ListGroup, Image, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PayPalButton } from 'react-paypal-button-v2';
import Message from '../components/Message';
import LoadSpinner from '../components/LoadSpinner';
import { calculateCheckout, youkeOrder } from '../actions/orderActions';
import { CART_CLEAR_ITEMS } from '../constants/cartConstants';

function OrderTouristScreen({ match, history }) {
    const dispatch = useDispatch();
    const [isPay, setIsPay] = useState(false);
    const [sdkReady, setSdkReady] = useState(false);

    const userLogin = useSelector(state => state.userLogin);
    const { userInfo } = userLogin;

    const { checkoutDetail, loading } = useSelector(state => state.order);
    const messageInfo = useSelector(state => state.message);
    const cart = useSelector(state => state.cart)
    console.log("Cart state:", cart, "Items List:", cart?.itemsList); // 调试信息
    const shippingAddress = useSelector(state => state.cart.shippingAddress);

    // Extract checkout details
    const itemsPrice = checkoutDetail?.items_price || 0;
    const yunfei = checkoutDetail?.shipping_fee || 0;
    const Shui = checkoutDetail?.tax || 0;
    const totalPrice = checkoutDetail?.final_price || 0;

    const addPayPalScript = () => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://www.paypal.com/sdk/js?client-id=AffNWpCeJZ9VLsUAWLrEatRj0RcQFiU-urRdNGmdchQl__OdrLclosh0UUkbGq57b2_Ao-b9ay3w30qn&currency=GBP';
        script.async = true;
        script.onload = () => setSdkReady(true);
        document.body.appendChild(script);
    };

    useEffect(() => {
        if (!isPay) {
            if (!window.paypal) {
                addPayPalScript();
            } else {
                setSdkReady(true);
            }

            // Fetch checkout details
            dispatch(calculateCheckout({ orderId: match.params.id }));
        }
    }, [dispatch, isPay, match.params.id]);

    const youkePay = () => {
        const param = {
            get_way: match.url.includes('zitiquhuo') ? '自提取货' : '送货上门',
            TotalPrices: itemsPrice,
            Yunfei: yunfei,
            Amount: totalPrice,
            Shui: Shui,
            ItemsJson: checkoutDetail?.items || [],
            email: shippingAddress.email,
        };
        dispatch(youkeOrder(param, () => {
            setIsPay(true);
            dispatch({ type: CART_CLEAR_ITEMS });
        }));
    };

    const successPaymentHandler = paymentResult => {
        youkePay();
    };

    return (
        <div className="OrderDetailScreen maxWidth">
            {messageInfo.msg && <Message variant={messageInfo.variant}>{messageInfo.msg}</Message>}

            {loading ? (
                <div className="fullscreen"><LoadSpinner /></div>
            ) : (
                <div>
                    <Row>
                        <Col md={8}>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <h2>Shipping</h2>
                                    <p><strong>Email: </strong><a href={`mailto:${shippingAddress.email}`}>{shippingAddress.email}</a></p>
                                    <p><strong>Address: </strong>{shippingAddress.address || 'Not Provided'}</p>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <h2>Payment Method</h2>
                                    <p><strong>Method: </strong>PayPal</p>
                                    {isPay ? (
                                        <Message variant="success">Paid Successfully</Message>
                                    ) : (
                                        <Message variant="danger">Not Paid</Message>
                                    )}
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <h2>Order Items</h2>
                                        {cart && cart.itemsList && cart.itemsList.length > 0 ? (
                                            <ListGroup variant="flush">
                                                {cart.itemsList.map((item) => {
                                                    console.log("Item in map:", item);
                                                    return (
                                                        <ListGroup.Item key={item.product}>
                                                            <Row>
                                                                <Col md={1}>
                                                                    <img
                                                                        style={{width: '100%', height: '100%'}}
                                                                        src={(item.image || '/default-image.jpg').split('?')[0]}
                                                                        alt={item.name || 'Unnamed'}
                                                                        className="img-fluid rounded"
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <Link
                                                                        to={`/product/${item.product}`}>{item.name || 'Unnamed'}</Link>
                                                                </Col>
                                                                <Col md={4}>
                                                                    {item.qty || 0} x £{(item.price?.A || 0).toFixed(2)} = £{((item.qty || 0) * (item.price?.A || 0)).toFixed(2)}
                                                                </Col>
                                                            </Row>
                                                        </ListGroup.Item>
                                                    );
                                                })}
                                            </ListGroup>
                                        ) : (
                                            <Message variant="info">Order is empty</Message>
                                        )}

                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col md={4}>
                            <Card>
                                <ListGroup variant="flush">
                                    <ListGroup.Item><h2>Order Summary</h2></ListGroup.Item>
                                    <ListGroup.Item><Row><Col>Subtotal:</Col><Col>£{itemsPrice.toFixed(2)}</Col></Row></ListGroup.Item>
                                    <ListGroup.Item><Row><Col>Shipping:</Col><Col>£{yunfei.toFixed(2)}</Col></Row></ListGroup.Item>
                                    <ListGroup.Item><Row><Col>VAT included:</Col><Col>£{Shui.toFixed(2)}</Col></Row></ListGroup.Item>
                                    <ListGroup.Item><Row><Col>Total:</Col><Col>£{totalPrice.toFixed(2)}</Col></Row></ListGroup.Item>
                                    {!isPay && (
                                        <ListGroup.Item>
                                            {!sdkReady ? <LoadSpinner /> : (
                                                <PayPalButton
                                                    amount={totalPrice.toFixed(2)}
                                                    currency="GBP"
                                                    onSuccess={successPaymentHandler}
                                                />
                                            )}
                                        </ListGroup.Item>
                                    )}
                                </ListGroup>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
}

export default OrderTouristScreen;
