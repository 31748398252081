/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, createRef } from 'react';
import { Button, Card, Flex, Input, Tabs, Typography, Checkbox, Modal, Spin } from 'antd';

import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  ArrowRightOutlined,
  BoldOutlined,
  CheckOutlined,
  CloseOutlined,
  CopyFilled,
  CopyOutlined,
  DeleteFilled,
  DeleteOutlined,
  ExclamationCircleFilled,
  EyeFilled,
  ItalicOutlined,
  LeftCircleOutlined,
  PoweroffOutlined,
  RightCircleOutlined,
  SearchOutlined,
  UnderlineOutlined,
} from '@ant-design/icons';
import Moveable from 'react-moveable';
import { TbFlipVertical } from 'react-icons/tb';
import { BiSolidLayerPlus } from 'react-icons/bi';
import { BiSolidLayerMinus } from 'react-icons/bi';
import { MdOutlineAlignVerticalCenter, MdOutlineAlignHorizontalCenter } from 'react-icons/md';
import { HiOutlineMagnifyingGlassMinus, HiOutlineMagnifyingGlassPlus } from 'react-icons/hi2';
import { useMediaQuery } from 'react-responsive';

import { useDispatch, useSelector } from 'react-redux';
import html2canvas from 'html2canvas';
import imageCompression from 'browser-image-compression';
import { defaultData } from './mock';
import { fabric } from 'fabric';

import ColorSelector from '../../components/ColorSelector';
import Warning from '../../img/warning.png';
import { addDesignToCart, getFresheners } from '../../actions/designActions';
import { addToCart } from '../../actions/cartActions';
import styles from '../../css/DesignerScreenV2.module.css';
import '../../css/DesignerScreen.css';
import { useReactive } from 'ahooks';
import {
  clearSelection,
  getBackBackgroundShape,
  getFrontBackgroundShape,
  handleObjectChange,
  recoverDesign,
  renderAllCanvas,
  resize,
  saveDesign,
  setOverlay,
  setWithWarningText,
  SIDES,
  TABS,
} from './tools';

import CanvasDomComponents from './components/CanvasDomComponents';
import PreviewModal from './components/PreviewModal';
import BackgroundPanel from './components/BackgroundPanel';
import AddTextPanel from './components/AddTextPanel';
import AddImagePanel from './components/AddImagePanel';
import ToolsPanel from './components/ToolsPanel';
import StickerPanel from './components/StickerPanel';
import CopyPanel from './components/CopyPanel';
import FontPanel from './components/FontPanel';

// 返回选中的状态
export const returnPrimary = (type, key) => {
  return type === key ? 'primary' : '';
};

const Index = ({ history }) => {
  const designInfo = useSelector((state) => state.designInfo);
  const tempShapeJson = useSelector((state) => {
    return designInfo?.fresheners?.jsonshape || defaultData;
  });

  const canvasDomWrap = useRef(null);
  const inputRef = useRef(null);

  // 页面状态
  const state = useReactive({
    front_canvas: null,
    back_canvas: null,
    isFocused: false,

    activeTool: TABS.TEXT,
    isFromHistory: false,

    showAttrPanel: false,

    isShowTool: false,
    toolPos: {
      left: 400,
      top: 300,
    },
  });

  // 预览弹窗的数据对象
  const previewModalState = useReactive({
    open: false,
    centered: true,
    title: '',
    type: '',
    row: {},
    viewShow: (type) => {
      previewModalState.open = true;
      previewModalState.title = 'Preview';
      previewModalState.type = type;
      previewModalState.row = {
        ...stage,
        tempShapeJson,
        historyMap,
        front: state.front_canvas,
        back: state.back_canvas,
      };
    },
    ok: () => {
      previewModalState.open = false;

      if (previewModalState?.type === 'addToBasket') {
        history.push('/cart');
      }
    },
    cancel: () => {
      previewModalState.open = false;
    },
  });

  const getCurrentCanvas = () => {
    return stage.currentType === SIDES.FRONT ? state.front_canvas : state.back_canvas;
  };

  // 左侧操作栏要操作的对象,当前的 canvas 和节点
  const stage = useReactive({
    name: 'Untitled',
    showWarning: true,

    currentType: 'front',
    canvas: null,
    allCanvas: {},
  });

  // 历史记录 对象
  const historyMap = useReactive({
    frontUndoHistory: [],
    frontRedoHistory: [],

    backUndoHistory: [],
    backRedoHistory: [],

    hasUndo: {},

    hasRedo: {},
  });

  const undo = () => {
    if (stage.currentType === SIDES.FRONT) {
      const redoAction = historyMap.frontUndoHistory.pop();
      const action = historyMap.frontUndoHistory[historyMap.frontUndoHistory.length - 1];
      historyMap.frontRedoHistory.push(redoAction);
      stage.canvas.loadFromJSON(action);
    } else {
      const redoAction = historyMap.backUndoHistory.pop();
      const action = historyMap.backUndoHistory[historyMap.backUndoHistory.length - 1];
      historyMap.backRedoHistory.push(redoAction);
      stage.canvas.loadFromJSON(action);
    }

    historyMap.hasUndo = {
      front: historyMap.frontUndoHistory.length > 1,
      back: historyMap.backUndoHistory.length > 1,
    };

    historyMap.hasRedo = {
      front: historyMap.frontRedoHistory.length > 0,
      back: historyMap.backRedoHistory.length > 0,
    };
  };

  const redo = () => {
    if (stage.currentType === SIDES.FRONT) {
      const action = historyMap.frontRedoHistory.pop();
      historyMap.frontUndoHistory.push(action);
      stage.canvas.loadFromJSON(action);
    } else {
      const action = historyMap.backRedoHistory.pop();
      historyMap.backUndoHistory.push(action);
      stage.canvas.loadFromJSON(action);
    }

    historyMap.hasUndo = {
      front: historyMap.frontUndoHistory.length > 1,
      back: historyMap.backUndoHistory.length > 1,
    };

    historyMap.hasRedo = {
      front: historyMap.frontRedoHistory.length > 0,
      back: historyMap.backRedoHistory.length > 0,
    };
  };

  const initEvent = () => {
    [state.front_canvas, state.back_canvas].forEach((canvas) => {
      canvas.on('mouse:up', (e) => mouseUp(e));

      canvas.on('object:added', (e) => showTools(e));

      canvas.on('selection:created', (e) => showTools(e));

      canvas.on('selection:cleared', (e) => {
        state.isShowTool = false;
      });
    });
  };

  const mouseUp = (e) => {
    console.log('e - >:', e);
    if (e?.target) {
      var target = e?.target;

      state.toolPos.left = target.oCoords.mtr.x;
      state.toolPos.top = target.oCoords.mtr.y - 50;

      state.isShowTool = true;

      if (target.type === 'textbox') {
        state.showAttrPanel = true;
      } else {
        state.showAttrPanel = false;
      }

      handleObjectChange({ ...stage, historyMap });
    } else {
      state.isShowTool = false;
      state.showAttrPanel = false;
    }
  };

  const showTools = (e) => {
    if (state.isFromHistory) {
      return;
    }

    if (e.selected || e.target) {
      const target = e.target || e.selected[0];

      state.toolPos.left = target.oCoords.mtr.x;
      state.toolPos.top = target.oCoords.mtr.y - 50;

      state.isShowTool = true;
    }

    if (e.deselected) {
      state.isShowTool = false;
    }

    state.isFromHistory = false;
  };

  const disposeCanvas = () => {
    state.front_canvas && state.front_canvas.dispose();
    state.back_canvas && state.back_canvas.dispose();
  };

  const isMobile = useMediaQuery({
    query: '(max-width: 800px)',
  });

  const showReOrder = () => {
    let reOrder = {};
    if (localStorage.getItem('reOrder')) {
      reOrder = JSON.parse(localStorage.getItem('reOrder'));
    }
    let product = reOrder.product || {};

    if (product.name) {
      stage.name = product.name;
    }

    let state1Data = {};
    if (product?.state1?.[0] == '{') {
      state1Data = JSON.parse(product?.state1);
    }

    let state2Data = {};
    if (product?.state2?.[0] == '{') {
      state2Data = JSON.parse(product?.state2);
    }

    let state1 = [];
    let state2 = [];

    if (state1Data.version) {
      state1 = state1Data;
    }
    if (state2Data.version) {
      state2 = state2Data;
    }

    recoverDesign({
      allCanvas: stage.allCanvas,
      frontData: state1,
      backData: state2,
    });
  };

  const initDom = async (param) => {
    fabric.Object.prototype.objectCaching = false;

    const initialOptions = {
      preserveObjectStacking: true,
      backgroundColor: '#fff',
      originX: 'center',
      originY: 'center',
      controlsAboveOverlay: true,
    };

    if (canvasDomWrap.current) {
      state.front_canvas = new fabric.Canvas('front', initialOptions);
      state.back_canvas = new fabric.Canvas('back', initialOptions);

      const frontBackgroundShape = await getFrontBackgroundShape(tempShapeJson);
      const backBackgroundShape = await getBackBackgroundShape(frontBackgroundShape);
      state.front_canvas.setBackgroundImage(frontBackgroundShape);
      state.back_canvas.setBackgroundImage(backBackgroundShape);

      await setOverlay({
        shape: tempShapeJson,
        fabric,
        front: state.front_canvas,
        back: state.back_canvas,
        withWarningText: true,
      });

      resize({
        containerRef: canvasDomWrap.current,
        isMobile: false,
        front: state.front_canvas,
        back: state.back_canvas,
        frontBackgroundShape,
        backBackgroundShape,
      });

      stage.canvas = getCurrentCanvas();
      stage.allCanvas = {
        front: state.front_canvas,
        back: state.back_canvas,
      };

      initEvent();
      showReOrder();
    }
  };
  useEffect(() => {
    // inputRef.current.focus({
    //   cursor: 'end',
    // });

    const designerDevice = localStorage.getItem('designer-device');
    if (!designerDevice) {
      const device = isMobile ? 'mobile' : 'desktop';
      localStorage.setItem('designer-device', device);
    }

    initDom();

    return () => {
      disposeCanvas();
    };
  }, [canvasDomWrap]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.leftActions}>
            <Button
              type={returnPrimary(state.activeTool, TABS.TEXT)}
              onClick={() => {
                state.activeTool = TABS.TEXT;
                clearSelection(stage.allCanvas);
                renderAllCanvas(stage.allCanvas);
              }}
            >
              Add Text
            </Button>
            <Button
              type={returnPrimary(state.activeTool, TABS.IMAGE)}
              onClick={() => {
                state.activeTool = TABS.IMAGE;
                clearSelection(stage.allCanvas);
                renderAllCanvas(stage.allCanvas);

                state.showAttrPanel = false;
              }}
            >
              Add Image
            </Button>
            <Button
              type={returnPrimary(state.activeTool, TABS.BG_COLOR)}
              onClick={() => {
                state.activeTool = TABS.BG_COLOR;
                clearSelection(stage.allCanvas);
                renderAllCanvas(stage.allCanvas);

                state.showAttrPanel = false;
              }}
            >
              BG Color
            </Button>
            <Button
              type={returnPrimary(state.activeTool, TABS.Sticker)}
              onClick={() => {
                state.activeTool = TABS.Sticker;
                clearSelection(stage.allCanvas);
                renderAllCanvas(stage.allCanvas);

                state.showAttrPanel = false;
              }}
            >
              Sticker
            </Button>
            <Button
              type={returnPrimary(state.activeTool, TABS.COPY)}
              onClick={() => {
                state.activeTool = TABS.COPY;
                clearSelection(stage.allCanvas);
                renderAllCanvas(stage.allCanvas);

                state.showAttrPanel = false;
              }}
            >
              Copy
            </Button>
          </div>
          <div className={styles.fullwidth}>
            {state.activeTool === TABS.TEXT && (
              <AddTextPanel
                {...{ ...stage, historyMap }}
                changeAttr={() => {
                  state.showAttrPanel = true;
                }}
              />
            )}
            {state.activeTool === TABS.IMAGE && <AddImagePanel {...{ ...stage, historyMap }} />}
            {state.activeTool === TABS.BG_COLOR && (
              <BackgroundPanel {...{ ...stage, historyMap }} />
            )}
            {state.activeTool === TABS.Sticker && <StickerPanel />}
            {state.activeTool === TABS.COPY && (
              <CopyPanel
                {...{ ...stage, historyMap }}
                copyedToggle={(type) => {
                  stage.currentType = type;
                  state.currentType = type;
                  stage.canvas = getCurrentCanvas();
                  state.isShowTool = false;
                }}
              />
            )}

            {state.showAttrPanel && <FontPanel {...{ ...stage, historyMap }} />}
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.rightTop}>
            <div className={[styles.rightTopBtns, styles.rightTopFirst].join(' ')}>
              <div className={styles.rightTopBtns}>
                <Input
                  ref={inputRef}
                  placeholder="Untitled"
                  className={styles.title}
                  onFocus={() => {
                    state.isFocused = true;
                  }}
                  onBlur={() => {
                    state.isFocused = false;
                  }}
                  value={stage.name}
                  onChange={(e) => {
                    stage.name = e.target.value;
                  }}
                />

                <Button
                  type="primary"
                  style={{ visibility: state.isFocused ? 'visible' : 'hidden' }}
                  icon={<CheckOutlined />}
                ></Button>
              </div>
              <div className={styles.rightTopBtns}>
                <Button
                  type="primary"
                  onClick={() => {
                    // saveDesign(stage);
                    previewModalState.viewShow('addToBasket');
                    state.isShowTool = false;
                  }}
                >
                  ADD TO BASKET
                </Button>
              </div>
            </div>
            <div className={styles.rightTopSecond}>
              <div className={styles.rightTopBtns}>
                <Button
                  icon={<LeftCircleOutlined />}
                  onClick={() => {
                    state.isShowTool = false;
                    state.isFromHistory = true;
                    undo();
                  }}
                  disabled={!historyMap.hasUndo[stage.currentType]}
                >
                  {isMobile ? '' : 'Undo'}
                </Button>
                <Button
                  icon={<RightCircleOutlined />}
                  onClick={() => {
                    state.isShowTool = false;
                    state.isFromHistory = true;
                    redo();
                  }}
                  disabled={!historyMap.hasRedo[stage.currentType]}
                >
                  {isMobile ? '' : 'Redo'}
                </Button>
              </div>
              <div>
                <Button
                  type={returnPrimary(stage.currentType, 'front')}
                  onClick={() => {
                    clearSelection(stage.allCanvas);
                    renderAllCanvas(stage.allCanvas);
                    stage.currentType = 'front';
                    stage.canvas = getCurrentCanvas();
                    state.isShowTool = false;
                    state.isFromHistory = false;
                  }}
                  style={{ borderRadius: '6px 0 0 6px', overflow: 'hidden' }}
                >
                  Front
                </Button>
                <Button
                  type={returnPrimary(stage.currentType, 'back')}
                  onClick={() => {
                    clearSelection(stage.allCanvas);
                    renderAllCanvas(stage.allCanvas);
                    stage.currentType = 'back';
                    stage.canvas = getCurrentCanvas();
                    state.isShowTool = false;
                    state.isFromHistory = false;
                  }}
                  style={{ borderRadius: '0 6px 6px 0', overflow: 'hidden' }}
                >
                  Back
                </Button>
              </div>
              <div className={styles.rightTopBtns}>
                <Button
                  icon={<EyeFilled />}
                  onClick={() => {
                    previewModalState.viewShow('');
                    state.isShowTool = false;
                  }}
                >
                  {isMobile ? '' : 'Preview'}
                </Button>
              </div>
            </div>
            <div className={styles.rightTopThird}>
              <Checkbox
                checked={stage.showWarning}
                onChange={(e) => {
                  stage.showWarning = e.target.checked;

                  setWithWarningText(stage);
                }}
              >
                Show Warning
              </Checkbox>
            </div>
          </div>

          <div ref={canvasDomWrap} className={styles.rightBottom} style={{ height: '600px' }}>
            {Object.keys(SIDES).map((side) => (
              <CanvasDomComponents key={side} type={side} currentType={stage.currentType} />
            ))}

            {state.isShowTool && <ToolsPanel {...state.toolPos} {...{ ...stage, historyMap }} />}
          </div>
        </div>
      </div>

      {previewModalState.open && <PreviewModal {...previewModalState} />}
    </>
  );
};

const ComponentsWithHooks = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const getList = async () => {
    const savedState = JSON.parse(localStorage.getItem('designInfo') || '{}');
    if (savedState.fresheners?.id) {
      await dispatch(getFresheners(savedState.fresheners.id));
    }
    setLoading(true);
  };

  useEffect(() => {
    getList();
  }, []);

  return !loading ? (
    <div
      style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Spin />
    </div>
  ) : (
    <Index {...props} />
  );
};

export default ComponentsWithHooks;
