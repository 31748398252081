import axios from 'axios';
import { messageUpdate } from './messageActions';
import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_NEW_LIST_REQUEST,
  PRODUCT_NEW_LIST_SUCCESS,
  PRODUCT_NEW_LIST_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_FAIL,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_CREATE_REVIEW_FAIL,
  PRODUCT_TOP_REQUEST,
  PRODUCT_TOP_SUCCESS,
  PRODUCT_TOP_FAIL,
  PRODUCT_CANCEL_FAIL,
  PRODUCT_NEW_DETAILS_SUCCESS,
} from '../constants/productConstants';
import { baseAPIUrl } from '../constants/apiConstants';

export const listProducts =
  (keyword = '', flag, id) =>
  async (dispatch) => {
    try {
      dispatch({ type: PRODUCT_LIST_REQUEST });

      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      let email = '';
      let userid = '';
      if (userInfo) {
        email = userInfo.email || '';
        userid = userInfo.userid || '';
      }

      let url = `${baseAPIUrl}product/store?id=${id}`;
      if (email && userid) url += `&email=${email}&userid=${userid}`;

      if (keyword) {
        url = `${baseAPIUrl}product/search?keyword=${keyword}`;
        if (email && userid) url += `&email=${email}&userid=${userid}`;
      }
      if (!flag) {
        url = `${baseAPIUrl}homepage`;
        if (email && userid) url += `?email=${email}&userid=${userid}`;
      }

      const { data } = await axios.get(url);

      dispatch({
        type: PRODUCT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PRODUCT_LIST_FAIL,
      });
      dispatch(
        messageUpdate(
          error.response && error.response.data.detail ? error.response.data.detail : error.message,
        ),
      );
    }
  };

export const listNewProducts =
  ({ keyword, productCollectionId, page = 1, pageSize = 10 }) =>
  async (dispatch) => {
    try {
      dispatch({ type: PRODUCT_NEW_LIST_REQUEST });

      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      let email = '';
      let userid = '';
      if (userInfo) {
        email = userInfo.email || '';
        userid = userInfo.userid || '';
      }

      let url = `${baseAPIUrl}alibaba/product/search?keyword=${keyword}&beginPage=${page}&pageSize=${pageSize}&country=en&productCollectionId=${productCollectionId}`;
      if (email && userid) url += `&email=${email}&userid=${userid}`;

      const { data } = await axios.get(url);

      dispatch({
        type: PRODUCT_NEW_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.error(error);

      dispatch({
        type: PRODUCT_NEW_LIST_FAIL,
      });
      dispatch(
        messageUpdate(
          error.response && error.response.data.detail ? error.response.data.detail : error.message,
        ),
      );
    }
  };

export const newProductDetails = (data) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST });

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let email = '';
    let userid = '';
    if (userInfo) {
      email = userInfo.email || '';
      userid = userInfo.userid || '';
    }

    const requestData = { ...data };
    if (email && userid) {
      requestData.email = email;
      requestData.userid = userid;
    }

    const res = await axios.post(`${baseAPIUrl}alibaba/product/detail`, requestData);

    const info = res?.data?.result?.result?.data?.[0] || {};

    dispatch({
      type: PRODUCT_NEW_DETAILS_SUCCESS,
      payload: info,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
    });
    dispatch(
      messageUpdate(
        error.response && error.response.data.detail ? error.response.data.detail : error.message,
      ),
    );
  }
};

export const listProductDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST });

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let email = '';
    let userid = '';
    if (userInfo) {
      email = userInfo.email || '';
      userid = userInfo.userid || '';
    }

    let url = `${baseAPIUrl}product/detail?pk=${id}`;
    if (email && userid) url += `&email=${email}&userid=${userid}`;

    const { data } = await axios.get(url);

    dispatch({
      type: PRODUCT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
    });
    dispatch(
      messageUpdate(
        error.response && error.response.data.detail ? error.response.data.detail : error.message,
      ),
    );
  }
};


/////////////////////////////////////////华丽的分割线//////////////////////////////////////////////////
/////////////////////////////////////////下面是没用的actions//////////////////////////////////////////////////
export const listTopProducts = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_TOP_REQUEST });

    const { data } = await axios.get(`/api/products/top/`);

    dispatch({
      type: PRODUCT_TOP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_TOP_FAIL,
      payload:
        error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const deleteProduct = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(`/api/products/delete/${id}/`, config);

    dispatch({
      type: PRODUCT_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const createProduct = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/products/create/`, {}, config);
    dispatch({
      type: PRODUCT_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_CREATE_FAIL,
      payload:
        error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const updateProduct = (product) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(`/api/products/update/${product._id}/`, product, config);
    dispatch({
      type: PRODUCT_UPDATE_SUCCESS,
      payload: data,
    });

    dispatch({
      type: PRODUCT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const createProductReview = (productId, review) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_CREATE_REVIEW_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/products/${productId}/reviews/`, review, config);
    dispatch({
      type: PRODUCT_CREATE_REVIEW_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_CREATE_REVIEW_FAIL,
      payload:
        error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const queryProductTotal = (color, combo, product) => async (dispatch, getState) => {
  try {
    if (!color || !combo) return;

    const { data } = JSON.parse(product.pinfojsonmember);
    const obj = data.find((item) => {
      return item.color === color && item.combo === combo;
    });
    if (obj) {
      product.ftotal = obj.total;
      product.fcolor = color;
      product.fcombo = combo;
      product.info.pricemember = obj.price;
    }
    dispatch({
      type: PRODUCT_UPDATE_SUCCESS,
      payload: product,
    });
    dispatch({
      type: PRODUCT_DETAILS_SUCCESS,
      payload: product,
    });
  } catch (error) {
    console.log(error);
  }
};
