import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { PayPalButton } from 'react-paypal-button-v2'
import Message from '../components/Message'
import LoadSpinner from '../components/LoadSpinner'
import '../css/OrderDetailScreen.css'
import { getOrderDetails, payOrder, deliverOrder } from '../actions/orderActions'

function OrderDetailScreen({ match, history }) {
    const orderId = match.params.id
    const dispatch = useDispatch()
    const [iwocaAccessToken, setIwocaAccessToken] = useState('8f06529e3d2611e04850e24bb4f3d29ef2afdb02');
    const handleIwocaPayment = async () => {
      const data = new FormData();
      data.append('amount', order.price);
      data.append('reference', orderId);
      data.append('allowed_payment_terms', 'PAY_LATER');

      try {
        const response = await axios.post('https://www.butt-and-co.co.uk/api/receive_post_request/', data);
        const { order_url } = response.data.data;
        window.location.href = order_url;
      } catch (error) {
        console.log(error);
      }
    }





    const orderInfo = useSelector(state => state.order)
    const orderDetail = orderInfo && orderInfo.orderDetail ? orderInfo.orderDetail : {}
    const order = orderDetail && orderDetail.orderdetail && orderDetail.orderdetail[0] ? orderDetail.orderdetail[0] : {}
    const messageInfo = useSelector(state => state.message)

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    const email = userInfo.email

    if (!orderInfo.loading && messageInfo.variant != 'error') {
    }



    useEffect(() => {


        if (!order || order.userorderid !== Number(orderId)) {

            dispatch(getOrderDetails(orderId))
        }
    }, [dispatch, order.userorderid, orderId])





    return (
        <div className="OrderDetailScreen maxWidth">
            {messageInfo.msg && <Message variant={messageInfo.variant}>{messageInfo.msg}</Message>}

            {orderInfo && orderInfo.loading? <div className="fullcreen"><LoadSpinner /></div> : <div>
                <h1 >Order: {order.Id}</h1>
                <div className="row">
                    <div className="col-md-8">
                        <div className="list-group list-group-flush">
                            <div className="list-group-item">
                                <h2  >Shipping</h2>
                                {/* <p><strong>Name: </strong> 329958887@qq.com</p> */}
                                <p><strong>Email: </strong><a href={"mailto:" + email}>{email}</a></p>
                                {order && order.addressid ? <p><strong>Shipping: </strong>{order.addressid.country},  {order.addressid.county},  {order.addressid.city},  {order.addressid.gs},  {order.addressid.address}</p>
                                    :
                                    (
                                        orderDetail && orderDetail.pickingaddress && orderDetail.pickingaddress[0] ?
                                            <p><strong>Picking Up: </strong> {orderDetail.pickingaddress[0].dizhi},{orderDetail.pickingaddress[0].dz1},{orderDetail.pickingaddress[0].dz2},{orderDetail.pickingaddress[0].dz3}</p>
                                            :
                                            <div role="alert" className="fade alert alert-warning show">Not Delivered</div>
                                    )

                                }


                            </div>
                            <div className="list-group-item">
                                <h2>Payment Method</h2>

                                <p><strong>Method: </strong> {order.huodaofukuan===1?'Cash on Delivery':'IwocaPay'}</p>
                                {
                                    order.status == 1 ? <div role="alert" className="fade alert alert-warning show">Not Paid</div> : <div role="alert" className="fade alert alert-success show">Paid Successfully</div>
                                }
                            </div>

                            {
                                orderDetail && orderDetail.productlist ?
                                    (
                                        <div className="list-group-item">
                                            <h2>Order Items</h2>
                                            <div className="list-group list-group-flush"> productlist
                                                    {
                                                    orderDetail.productlist.map((item, index) => {
                                                        return <div className="list-group-item">
                                                            <div className="row"><div className="col-md-1">
                                                                <img style={{ width: '100%', height: '100%' }} src={item.product.image} alt={item.product.name} className="img-fluid rounded" />
                                                            </div>
                                                                <div className="col">
                                                                    <a href={"#/product/" + item.product.id}>{item.product.name}</a>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div>{item.quantity} X £{item.price} = £{(item.quantity * item.price * item.product.discount).toFixed(2)}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>

                                    )
                                    :
                                    <div role="alert" className="fade alert alert-info show">Order is empty</div>
                            }

                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="list-group list-group-flush">
                                <div className="list-group-item"><h2>Order Summary</h2></div>
                                <div className="list-group-item"><div className="row"><div className="col">Subtotal:</div><div className="col">£{(order.price  - order.yunfei).toFixed(2)}</div></div></div>
                                <div className="list-group-item"><div className="row"><div className="col">Shipping:</div><div className="col">£{order.yunfei}</div></div></div>
                                <div className="list-group-item"><div className="row"><div className="col">VAT included:</div><div className="col">£{order.shui}</div></div></div>
                                <div className="list-group-item"><div className="row"><div className="col">Total:</div><div className="col">£{order.price}</div></div></div>

                                {+order.status === 1 && +order.huodaofukuan!==1 && (
                                    <div className="list-group-item">
                                      {handleIwocaPayment && handleIwocaPayment.loading ? (
                                        <LoadSpinner />
                                      ) : (
                                        <button className="payment-button" onClick={handleIwocaPayment}>
                                          Pay with Iwoca
                                        </button>
                                      )}
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }

        </div>
    )

}

export default OrderDetailScreen
